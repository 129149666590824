import "core-js/modules/web.timers.js";
;
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  var setupForm = function setupForm() {
    var form = document.getElementById('send-message-form');
    if (!form) return;
    form.addEventListener('submit', function (event) {
      event.preventDefault(); // Previeni l'invio predefinito del form

      var form = this;
      var preloader = document.getElementById('preloader');
      var submitButton = document.getElementById('btn-submit');
      var textarea = $('#message');
      var fermino = $('#fermino');
      preloader.style.display = 'block';
      submitButton.disabled = true;
      setTimeout(function () {
        fermino.val(textarea.val().length);
        form.submit();
      }, 500);
    });
  };
  var setupMessageCounter = function setupMessageCounter() {
    var maxChars = 400;
    var textarea = $('#message');
    textarea.keyup(function () {
      if (textarea.val().length > maxChars) {
        textarea.val(textarea.val().substring(0, maxChars));
      }
    });
  };
  $(function () {
    $('body').removeClass('preloading');
    setupForm();
    setupMessageCounter();
    var $success = $('.success');
    if ($success.length) {
      setTimeout(function () {
        window.location.href = '/';
      }, 10000);
    }
  });
});